


























import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import * as SchoolApi from "@/api/school";
import * as TestPaperApi from "@/api/testPaper";
import { ExamSchool, ResourceTemplateTestPaperUseAuth } from "@/tool/_class";
import * as _ from "lodash";
import { uuid } from "@/tool";
import { TestPaper } from "./index";
//import * as hash from "hash-sum";
//const hash = require("hash-sum");

class TestPaperUseAuth {
  testPaperUseAuthId: string = "";
  testPaperId: string = "";
  schoolId: string = "";
}

class TransferDataItem extends ExamSchool {
  disabled: boolean = false;
}

@Component({
  name: "TestPaperUseAuthEditor",
  components: {
    cardBox,
  },
})
export default class extends Vue {
  private title: string = "试卷使用授权";
  private schools: Array<TransferDataItem> = [];
  private authSchools: Array<string> = [];
  private testPapers: TestPaper[] = [];
  private get testPaperIds(): string[] {
    if (this.$route.query.testPaperIds) {
      return this.$route.query.testPaperIds as string[];
    }
    return [];
  }
  private get useAuthTo(): string {
    if (this.$route.query.useAuthTo) {
      return this.$route.query.useAuthTo as string;
    }
    return "right";
  }

  private loading: boolean = false;
  private async __initData() {
    try {
      this.loading = true;
      const resps: any[] = await Promise.all([
        SchoolApi.getSchoolList({
          pageSize: 9999,
          curPage: 1,
        }),
        TestPaperApi.getTestPaperUseAuthList({
          curPage: 1,
          pageSize: 9999,
          testPaperId:
            this.testPaperIds.length == 1 ? this.testPaperIds[0] : "",
          testPaperIds: this.testPaperIds || [],
        }),
        TestPaperApi.getTestPaperList({
          curPage: 1,
          pageSize: 9999,
          gradeNo: "",
          keyWords: "",
          testPaperIds: this.testPaperIds,
        }),
        TestPaperApi.getResourceTemplateTestPaperUseAuthList({
          curPage: 1,
          pageSize: 9999,
          testPaperIds: this.testPaperIds || [],
        }),
      ]);

      this.schools.splice(0, this.schools.length);
      resps[0].data.items.forEach((element: any) => {
        let item: TransferDataItem = new TransferDataItem();
        _.merge(item, element);
        //如果在资源模板授权了，在此不能单独再授权或取消授权
        item.disabled = resps[3].data.items.some(
          (v: ResourceTemplateTestPaperUseAuth) => {
            return v.schoolId == item.schoolId;
          }
        );
        this.schools.push(item);
        console.log(`school item: ${JSON.stringify(item)}`);
      });
      //
      const testPaperAuthList: TestPaperUseAuth[] = resps[1].data.items;
      resps[3].data.items.forEach(
        (element: ResourceTemplateTestPaperUseAuth) => {
          if (
            testPaperAuthList.some((item) => {
              return (
                element.schoolId == item.schoolId &&
                element.testPaperId == item.testPaperId
              );
            })
          ) {
            //已存在
          } else {
            testPaperAuthList.push(
              _.merge(
                new TestPaperUseAuth(),
                _.pick(element, ["schoolId", "testPaperId"])
              )
            );
          }
        }
      );
      this.authSchools.splice(0, this.authSchools.length);
      switch (this.useAuthTo) {
        case "left": {
          //向左移，即为未选，所以只要一有学校与授权列表对上即可
          testPaperAuthList.forEach((element: any) => {
            const school = this.schools.find((school: TransferDataItem) => {
              return school.schoolId == element.schoolId;
            });
            if (school && this.authSchools.indexOf(school.schoolId) < 0) {
              this.authSchools.push(school.schoolId);
            }
          });
          break;
        }
        case "right": {
          //向右移，即为已选，所以要所有授权记录，与试卷列表都要对上才视为选上
          this.schools.forEach((element: TransferDataItem) => {
            const tmpArr: any[] = _.uniqBy(
              testPaperAuthList.map((auth: any) => {
                return {
                  schoolId: auth.schoolId,
                  testPaperId: auth.testPaperId,
                };
              }),
              JSON.stringify
            ).filter((value: any) => {
              return value.schoolId == element.schoolId;
            });
            if (tmpArr.length == this.testPaperIds.length) {
              this.authSchools.push(element.schoolId);
            }
          });
          break;
        }
      }
      //
      this.testPapers = resps[2].data.items;
      switch (this.testPapers.length) {
        case 0: {
          this.title = "无";
          break;
        }
        case 1: {
          this.title = `试卷使用授权 - ${this.testPapers[0].testPaperName}`;
          break;
        }
        default: {
          this.title = `试卷使用授权 - ${this.testPapers[0].testPaperName}...等${this.testPapers.length}份试卷`;
        }
      }
    } finally {
      this.loading = false;
    }
  }

  private filterMethod(query: string, option: TransferDataItem) {
    if (!query.trim()) {
      return true;
    }
    let schoolSections: string[] = [];
    if (option.primarySchool) {
      schoolSections.push("小学");
    }
    if (option.middleSchool) {
      schoolSections.push("中学");
    }
    if (option.highSchool) {
      schoolSections.push("高中");
    }
    let ss: string = `${option.schoolName}-${schoolSections.join("|")}-${
      option.areaName
    }`;
    //
    let keyWords: string[] = query.split(" ");
    if (!keyWords.length) {
      keyWords = query.split(",");
    }
    if (!keyWords.length) {
      keyWords = query.split(";");
    }
    //
    let result: boolean = true;
    for (let i = 0; i < keyWords.length; i++) {
      result = result && ss.indexOf(keyWords[i]) > -1;
    }
    return result;
  }

  private renderContentFunc(h: any, option: TransferDataItem) {
    let schoolSections: string[] = [];
    if (option.primarySchool) {
      schoolSections.push("小");
    }
    if (option.middleSchool) {
      schoolSections.push("中");
    }
    if (option.highSchool) {
      schoolSections.push("高");
    }
    return h("div", {}, [
      h(
        "span",
        {
          attrs: {
            title: `${option.schoolName}${option.registered ? "" : "(未注册)"}`,
          },
          style: {
            color: option.registered
              ? !option.disabled
                ? "black"
                : "#8492a6"
              : "red",
          },
        },
        `${option.schoolName}[${schoolSections.toString()}]`
      ),
      h(
        "span",
        {
          style: {
            float: "right",
            color: "#8492a6",
            fontSize: "13px",
          },
        },
        option.areaName
      ),
    ]);
    /*
    return h('span', {
      attrs: {
        title: `${option.schoolName}${(option.registered) ? "" : "(未注册)"}`
      },
      style: {
        color: (option.registered) ? "black" : "red"
      }
    }, option.schoolName);
    */
  }

  private async handleChange(
    value: string[],
    direction: string,
    movedKeys: string[]
  ) {
    for (let i = 0; i < this.testPaperIds.length; i++) {
      let testPaperId: string = this.testPaperIds[i];
      for (let k = 0; k < movedKeys.length; k++) {
        const { data } = await TestPaperApi.getTestPaperUseAuthList({
          curPage: 1,
          pageSize: 1,
          testPaperId: testPaperId,
          schoolId: movedKeys[k],
        });

        switch (direction) {
          case "left": {
            if (data.items.length > 0) {
              await TestPaperApi.delTestPaperUseAuth(
                data.items[0].testPaperUseAuthId
              );
            }
            break;
          }
          case "right": {
            if (data.items.length <= 0) {
              let auth: TestPaperUseAuth = new TestPaperUseAuth();
              auth.testPaperUseAuthId = uuid();
              auth.testPaperId = testPaperId;
              auth.schoolId = movedKeys[k];
              await TestPaperApi.postTestPaperUseAuth(auth);
            }
            break;
          }
        } //switch
      } //for k
    } //for i

    console.log(JSON.stringify(value), direction, JSON.stringify(movedKeys));
  }

  private doGoBackClick() {
    this.$router.go(-1);
  }

  mounted() {
    this.__initData();
  }
}
